export const verhuurPlaatsen = [
  "Hoorn",
  "Zwaag",
  "Blokker",
  "Berkhout",
  "Zwaagdijk",
  "Wognum",
  "Oosterblokker",
  "Westwoud",
  "Hoogkarspel",
  "Nibbixwoud",
  "Midwoud",
  "Wervershoof",
  "Enkhuizen",
  "Avenhorn",
  "Spierdijk",
  "Schellinkhout",
  "Bovenkarspel",
  "Spanbroek",
  "Opmeer",
  "Obdam",
  "De Goorn",
  "Risdam-Noord",
  "Risdam",
  "Risdam-Zuid",
  "Kersenboogerd",
  "Grote Waal",
  "Westerblokker",
  "Nieuwe Steen",
  "Bangert-Oosterpolder",
  "Hoorn-Noord",
];

export const verhuurKeywords = [
  { keyword: "auto_huren", type: "personen" },
  { keyword: "huurauto", type: "personen" },
  { keyword: "autoverhuur", type: "personen" },
  { keyword: "auto_verhuur", type: "personen" },
  { keyword: "verhuiswagen", type: "bestel" },
  { keyword: "bestelwagen", type: "bestel" },
  { keyword: "verhuiswagen_huren", type: "bestel" },
  { keyword: "verhuiswagen_verhuur", type: "bestel" },
  { keyword: "bestelwagen_huur", type: "bestel" },
  { keyword: "bestelwagen_verhuur", type: "bestel" },
  { keyword: "bestelwagen_huren", type: "bestel" },
  { keyword: "busje_huren", type: "bestel" },
  { keyword: "vrachtwagen", type: "vracht" },
  { keyword: "vrachtwagen_verhuur", type: "vracht" },
  { keyword: "vrachtwagen_huren", type: "vracht" },
  { keyword: "verhuisbus", type: "bestel" },
  { keyword: "verhuisbusje", type: "bestel" },
  { keyword: "rolstoelbus", type: "personen" },
  { keyword: "personenbus", type: "personen" },
  { keyword: "personenbusje", type: "personen" },
];
