import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
// import HomeView from "../views/HomeView.vue";
import ActiesPagina from "@/views/ActiesPagina.vue";
import SitemapPagina from "@/views/SitemapPagina.vue";
import { verhuurKeywords } from "@/generiek";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    // component: HomeView,
    component: () => import("../views/ContentPagina.vue"),
    props: { slug: "home" },
    meta: {
      title: "RightRent autoverhuur - de juiste keuze!",
    },
  },
  {
    path: "/over-rightrent",
    alias: ["/Over_RightRent.aspx"],
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      title: "Over RightRent",
    },
  },
  {
    path: "/acties",
    alias: ["/Acties.aspx"],
    name: "acties",
    component: () => import("../views/ContentPagina.vue"),
    props: { slug: "acties" },
  },
  // TODO: Nieuws op /nieuws (en /nieuws.aspx?)
  {
    path: "/sitemap",
    alias: ["/Sitemap.aspx"],
    name: "sitemap",
    component: SitemapPagina,
    meta: {
      title: "Sitemap",
    },
  },
  {
    // TODO: aan banden leggen van mogelijke plaatsnamen om misbruik te voorkomen
    path: "/:plaats/:keyword",
    alias: ["/:plaats/:keyword.aspx"],
    name: "voertuigen-lijst",
    component: () => import("../views/VoertuigenLijstPagina.vue"),
    props: (route) => {
      const keyword = route.params.keyword as string;
      const categorie = verhuurKeywords.find((kw) => kw.keyword === keyword.replace(/-/g, "_"))?.type;
      return { plaats: route.params.plaats, categorie, keyword };
    },
    meta: {
      title: (route: RouteLocationNormalized) => {
        const keyword = (route.params.keyword as string).replace(/-/g, " ");
        return `RightRent ${keyword} in ${route.params.plaats}`;
      },
    },
  },
  // {
  //   // TODO: aan banden leggen van mogelijke plaatsnamen om misbruik te voorkomen
  //   path: "/:plaats/bestelwagen-verhuur",
  //   alias: ["/:plaats/bestelwagen_verhuur.aspx"],
  //   name: "bestelwagen-verhuur",
  //   component: () => import("../views/VoertuigenLijstPagina.vue"),
  //   props: (route) => ({ plaats: route.params.plaats, categorie: "bestel" }),
  // },
  // {
  //   // TODO: aan banden leggen van mogelijke plaatsnamen om misbruik te voorkomen
  //   path: "/:plaats/vrachtwagen-verhuur",
  //   alias: ["/:plaats/vrachtwagen_verhuur.aspx"],
  //   name: "vrachtwagen-verhuur",
  //   component: () => import("../views/VoertuigenLijstPagina.vue"),
  //   props: (route) => ({ plaats: route.params.plaats, categorie: "vracht" }),
  // },
  {
    path: "/:plaats/:keyword/Klasse_:klasseCode/:automerkEnTypeVoorbeeld",
    name: "voertuig-details",
    component: () => import("../views/VoertuigDetailsPagina.vue"),
    props: (route) => ({
      plaats: route.params.plaats,
      klasseCode: route.params.klasseCode,
      keyword: route.params.keyword,
      automerkEnTypeVoorbeeld: route.params.automerkEnTypeVoorbeeld,
    }),
    meta: {
      title: (route: RouteLocationNormalized) => {
        const plaats = (route.params.plaats as string).replace(/-/g, " ");
        const keyword = (route.params.keyword as string).replace(/-/g, " ");
        const automerkEnTypeVoorbeeld = (route.params.automerkEnTypeVoorbeeld as string).replace(/-/g, " ");
        return `RightRent ${keyword} in ${plaats} - ${automerkEnTypeVoorbeeld}`;
      },
    },
  },
  {
    path: "/info/:slug",
    name: "info",
    component: () => import("../views/ContentPagina.vue"),
    props: true,
    meta: {
      // Zetten van titel is hier niet nodig, wordt gedaan door de ContentPagina component adhv "Naam" veld in database
      title: (route: RouteLocationNormalized) => `RightRent - ${(route.params.slug as string).replace(/-/g, " ")}`,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "Loading...";
  if (typeof to.meta.title === "string") {
    document.title = to.meta.title;
  } else if (typeof to.meta.title === "function") {
    document.title = to.meta.title(to);
  } else {
    document.title = "RightRent Autoverhuur Hoorn";
  }
  next();
});

export default router;
