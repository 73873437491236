<template>
  <a id="top"></a>
  <div id="container">
    <table id="topLogo">
      <tr>
        <td>
          <router-link to="/" title="Terug naar homepage">
            <img
              src="./assets/rightrent_logo_klein.gif"
              width="149"
              height="86"
              alt="RightRent autoverhuur - De juiste keuze!"
              style="float: left; clear: left; border: 0px"
            />
          </router-link>
        </td>
        <td>
          <img
            src="./assets/Verhuur_van_tekst.gif"
            width="374"
            height="24"
            alt="Verhuur van personenauto's, bestel- en vrachtwagens"
          /><br />
          <img src="./assets/De_juiste_keuze.gif" width="117" height="20" alt="De juiste keuze!" />
        </td>
        <td>
          <b>RightRent Autoverhuur</b><br />
          Dr C.J.K. van Aalstweg 7b, Hoorn<br />
          <img class="icon" align="absmiddle" src="./assets/icons/icon_phone.gif" alt="Telefoon" />0229 - 291 691<br />
          <img class="icon" align="absmiddle" src="./assets/icons/icon_email.gif" alt="E-mail" /><a
            href="mailto:info@rightrent.nl"
            >info@rightrent.nl</a
          >
        </td>
      </tr>
    </table>

    <div id="topmenu" class="menu">
      <ul class="leftItems">
        <li><router-link to="/">Home</router-link></li>
        <li>
          <router-link to="/over-rightrent">Over RightRent</router-link>
        </li>
        <li><router-link to="/acties">Acties</router-link></li>
      </ul>
      <ul class="rightItems">
        <li>
          <router-link to="/Hoorn/auto-verhuur" id="A1">Personenauto's</router-link>
        </li>
        <li>
          <router-link to="/Hoorn/bestelwagen-verhuur" id="A2">Bestelwagens</router-link>
        </li>
        <li>
          <router-link to="/Hoorn/vrachtwagen-verhuur" id="A3">Vrachtwagens</router-link>
        </li>
      </ul>
    </div>

    <!-- <asp:contentplaceholder id="ContentPlaceHolder" runat="server">
      <h1>Titel</h1>
      <p>
        Inleidend verhaal... Inleidend verhaal... Inleidend verhaal... Inleidend
        verhaal... Inleidend verhaal... Inleidend verhaal...
      </p>
    </asp:contentplaceholder> -->
    <router-view />

    <div id="bottommenu" class="menu">
      <ul class="leftItems">
        Copyright &copy;
        {{
          new Date().getFullYear()
        }}
        RightRent autoverhuur
      </ul>
      <ul class="rightItems">
        <li><router-link to="/sitemap">Sitemap</router-link></li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
body {
  margin: 0px;
  padding: 0px;
  font-family: Verdana;
  font-size: 70%;
  color: #444;
  line-height: 170%;
}
h1 {
  font-size: 130%;
  color: #e2001a;
  padding: 0px;
  padding-top: 15px;
  margin: 0px 0px 15px 0px;
}
h2 {
  font-size: 110%;
  color: #e2001a;
  padding: 0px;
  padding-top: 10px;
  margin: 0px 0px 10px 0px;
}
h3 {
  font-size: 100%;
  color: #e2001a;
  padding: 0px;
  margin: 10px 0px 10px 0px;
}
p {
  margin: 10px 0px 10px 0px;
}
a {
  color: #e2001a;
}

img.icon {
  margin: 4px 5px 0px 0px;
}
div#container {
  margin: auto;
  width: 800px;
}
table#topLogo {
  vertical-align: bottom;
  margin-bottom: 20px;
  width: 100%;
}
table#topLogo td {
  vertical-align: bottom;
}

div.menu {
  text-align: right;
}
div.menu a {
  text-decoration: none;
}
div#topmenu {
  font-size: 110%;
  font-weight: bold;
  background-color: #e2001a;
  color: White;
}
div#topmenu a {
  color: White;
}
div#bottommenu {
  margin-top: 20px;
  border-top: 1px solid #e2001a;
  color: #e2001a;
}
div#bottommenu a {
  color: #e2001a;
}
div.menu ul.leftItems {
  list-style-type: none;
  padding: 2px;
  margin: 0px;
  float: left;
  clear: left;
}
div.menu li {
  display: inline;
  margin: 0px 20px 0px 20px;
}
div.menu ul.rightItems {
  list-style-type: none;
  padding: 2px;
  margin: 0px;
}
</style>
