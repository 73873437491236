<template>
  <h1>RightRent Sitemap</h1>

  <ul>
    <li><a href="/">Homepage</a></li>
    <li><a href="/nieuws">Nieuws</a></li>
    <li><a href="/over-rightrent">Over RightRent</a></li>

    <li><a id="A1" href="/Hoorn/auto-verhuur">Personen auto verhuur</a></li>
    <li><a id="A2" href="/Hoorn/bestelwagen-verhuur">Bestelwagen / verhuiswagen verhuur</a></li>
    <li><a id="A3" href="/Hoorn/vrachtwagen-verhuur">Vrachtwagen verhuur</a></li>
  </ul>

  <div
    style="width: 10px; height: 10px; cursor: wait"
    onclick="document.getElementById('plaatsen').style.display='block'"
  >
    &nbsp;
  </div>
  <a href="#" onclick="document.getElementById('plaatsen').style.display='block'">Meer..</a>

  <div id="plaatsen" style="display: none">
    RightRent levert in regio Hoorn:
    <ul>
      <template v-for="plaats in plaatsen" :key="plaats">
        <li v-for="keyword in keywords" :key="keyword">
          <a :href="`/${plaats.replace(/ /g, '-')}/${keyword.replace(/_/g, '-')}`"
            >{{ keyword.replace(/_/g, " ") }} {{ plaats }}</a
          >
        </li>
      </template>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { verhuurKeywords, verhuurPlaatsen } from "@/generiek";
import { ref } from "vue";

const plaatsen = ref(verhuurPlaatsen);
const keywords = ref(verhuurKeywords.map((k) => k.keyword));
</script>
